<template>
  <div class="FindByAccountCode" v-fixed>
    <div class="topbar">
      <img @click="$router.go(-1)" :src='require("@/assets/imgs/back.png")' style="width: .8rem;height: .6rem;    margin-left: .64rem;"/>
      <div class="head-title">{{ $t("憑證上傳") }}</div>
    </div>

    <div class="content">
      <div class="img-upload">
        <input
          type="file"
          class="img-upload-input"
          id="img-upload-input"
          accept="image/jpg, image/jpeg, image/png, image/gif"
          @change="uploadImage"
        />
        <label
          class="img-upload-label text-center p-4 position-absolute"
          id="img-upload-label"
          for="img-upload-input"
        >
          <img :src="UploadImg" alt="" />
          <div class="mt-2">{{ $t("請上傳您的帳號憑證") }}</div>
          <v-btn height="50" block class="rounded-lg mt-2 btn1" bottom>
            {{ $t("上傳") }}
          </v-btn>
        </label>
      </div>
    </div>

    <!-- 重置密码 -->
    <v-card v-if="showReset" elevation="0">
      <v-form class="mt-5" ref="form">
        <v-text-field
          dense
          readonly
          color="info"
          outlined
          v-model="account"
          prepend-inner-icon="mdi-account"
          class="rounded-lg"
        ></v-text-field>
        <v-text-field
          dense
          color="info"
          prepend-inner-icon="mdi-lock"
          :placeholder="$t('請輸入密碼')"
          outlined
          clearable
          v-model="password1"
          :type="'password'"
          :rules="[rules.required, rules.counter, rules.password1]"
          class="rounded-lg"
        ></v-text-field>
        <v-text-field
          dense
          color="info"
          prepend-inner-icon="mdi-lock"
          :placeholder="$t('請再次輸入密碼')"
          outlined
          clearable
          v-model="password2"
          :type="'password'"
          :rules="[rules.required, rules.password2]"
          class="rounded-lg"
        ></v-text-field>
        <v-btn
          height="50"
          block
          class="rounded-lg mt-1 btn1"
          bottom
          @click="resetPassword"
        >
          {{ $t("確定") }}
        </v-btn>
      </v-form>
    </v-card>
    <canvas
      id="qrcanvas"
      width="390"
      height="844"
      style="display: none"
    ></canvas>
    <Dialog ref="hintDialog" />
  </div>
</template>

<script>
import i18n from "@/i18n/index";
import QrCode from "qrcode-decoder";
import jsQR from "jsqr";
export default {
  data() {
    return {
      UploadImg: require("@/assets/imgs/UploadImg.png"),
      safedata: "",
      password1: "",
      password2: null,
      showReset: false,
      base64Data: null,
      key: "",
      rules: {
        required: (value) => !!value || i18n.t("必填項"),
        counter: (value) =>
          (value && value.length >= 6 && value.length <= 20) ||
          i18n.t("密碼必須為6-20位英文+數字"),
        password1: (value) => {
          const pattern = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/;
          return pattern.test(value) || i18n.t("密碼格式英文+數字6-20位");
        },
        password2: (value) =>
          value === this.password1 || i18n.t("兩次密碼不一致"),
      },
    };
  },
  methods: {
    resetPassword() {
      if (this.$refs.form.validate()) {
        this.$post("/Api/Account/ResetPassword", {
          Password: this.password1,
          ConfirmPassword: this.password2,
          Key: this.key,
        }).then((res) => {
          this.$refs.hintDialog.dialog2 = true;
          this.$refs.hintDialog.text1 = i18n.t("溫馨提示");
          this.$refs.hintDialog.text2 = i18n.t("您的密碼已修改，請重新登錄");
          this.$refs.hintDialog.skipOrder = "/login";
        });
      }
    },

    uploadImage(e) {
      //  图片数量校验
      if (e.target.files.length > 1) {
        this.$toast(i18n.t("最多選擇1張憑證"));
        return;
      }
      if (window.FileReader) {
        var file = e.target.files[0];
        var fr = new FileReader();
        fr.readAsDataURL(file);
        fr.onloadend = (e) => {
          this.base64Data = e.target.result;
          this.resolveQR(this.base64Data);
        };
      }
    },

    // 二维码识别  使用时触发的函数
    resolveQR(data) {
      var c = document.getElementById("qrcanvas");
      var ctx = c.getContext("2d");
      var width = 390;
      var height = 844;
      var img = new Image();
      img.src = data;
      img.onload = () => {
        ctx.drawImage(img, 0, 0, width, height);
        var imageData = ctx.getImageData(0, 0, width, height);
        const code = jsQR(imageData.data, width, height, {
          inversionAttempts: "dontInvert",
        });
        if (code) {
          //二维码内容
          code.data = JSON.parse(code.data);
          this.account = code.data.account;
          this.key = code.data.key;
          if (!this.account || !this.key) {
            this.$toast(i18n.t("二維碼識別失敗，請重新上傳"));
            return;
          }
          this.showReset = true;
          this.$toast(i18n.t("二維碼識別成功"));
        } else {
          this.$toast(i18n.t("二維碼識別失敗，請重新上傳"));
        }
      };
    },
  },
  directives: {
    fixed: {
      // inserted 被绑定元素插入父节点时调用
      inserted() {
        let scrollTop =
          document.body.scrollTop || document.documentElement.scrollTop;
        document.body.style.cssText +=
          "position:fixed;width:100%;top:-" + scrollTop + "px;";
      },
      // unbind 指令与元素解绑时调用
      unbind() {
        let body = document.body;
        body.style.position = "";
        let top = body.style.top;
        document.body.scrollTop = document.documentElement.scrollTop =
          -parseInt(top);
        body.style.top = "";
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.FindByAccountCode {
  padding-top: 46px;
  .topbar {
    display: flex;
    align-items: center;
    background-color: #F0F6F9;
    text-align: left;
    height: 46px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;
    .v-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 24px;
    }
    .head-title {
      position: absolute;
      line-height: 1;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      left: 50%;
      font-size: 18px;
      color: #485573;
    }
  }
  .content {
    font-size: 14px;
    padding: 28px 0;
    margin: 0 28px;
    display: flex;
    justify-content: space-between;
    .img-upload {
      position: relative;
      display: inline-block;
      width: 100%;
      margin-bottom: 0;
      height: calc(3.5rem + 100px);
    }

    .img-upload-input {
      position: relative;
      z-index: 2;
      width: 100%;
      height: calc(3rem + 120px);
      margin: 0;
      opacity: 0;
    }

    .img-upload-label {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      text-align: center;
      padding: 1.5rem;
      border: 1px dashed #808080;
      background: rgba(255, 255, 255, 0.7);
border: 1px dashed #C5D4DB;
border-radius: 10px;
    }
  }

  .form-title {
    margin-top: 20px;
    padding: 0 3px;
    font-size: 28px;
    color: #333333;
    font-weight: 600;
    text-align: left;
  }
  .form-switch {
    display: flex;
    .sign-up {
      display: flex;
      align-items: center;
      font-size: 18px;
      color: #c9c9c9;
      width: 30%;
      text-align: left;
      padding: 8px 5px;
    }
    .fast-sign {
      display: flex;
      align-items: center;
      font-size: 18px;
      color: #c9c9c9;
      width: 70%;
      text-align: left;
      padding: 8px 5px;
    }
    .active {
      font-size: 25px;
      font-weight: 700;
      color: #f84279;
      border-bottom: 1px solid #f84279;
    }
  }
  .v-card {
    position: absolute;
    padding: 0 24px 30px;
    left: 0;
    right: 0;
    border-radius: 0px 0px 40px 40px;
    box-shadow: 0px 10px 25px #dc2159;
    background-color: #F0F6F9;
    z-index: 100;
  }

  .btn1 {
    background: linear-gradient(91.69deg, #f84077 16.39%, #f95e93 139.39%);
    box-shadow: 0px 15px 20px rgba(245, 86, 136, 0.2);
    color: #fff;
    font-size: 16px;
  }
}
</style>
